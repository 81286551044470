export const ContributorIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='w-8 h-8 text-teal-500' viewBox='0 0 20 20' fill='currentColor'>
    <path d='M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z' />
  </svg>
);

// Icons.js
export const TotalContributionsIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='w-8 h-8 text-teal-500' viewBox='0 0 20 20' fill='currentColor'>
    <path
      fillRule='evenodd'
      d='M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z'
      clipRule='evenodd'
    />
  </svg>
);

export const GitHubStarsIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='w-8 h-8 text-teal-500' viewBox='0 0 20 20' fill='currentColor'>
    <path d='M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z' />
  </svg>
);

export const ForksIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='w-8 h-8 text-teal-500' viewBox='0 0 20 20' fill='currentColor'>
    <path
      fillRule='evenodd'
      d='M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
      clipRule='evenodd'
    />
  </svg>
);
